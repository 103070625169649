.AlertQRpage .MuiDialog-paper {
  width: fit-content;
  max-width: 700px;
  height: auto;
  max-height: 280px;
  padding: 40px;
}

.AlertQRpage .MuiDialogContentText-root {
  font-size: 3em;
  color: #ff0000;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.AlertQRpage .MuiDialog-root .MuiFade-child {
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
