.portal {
  width: 100vw;
  height: 100vh;
}

.buttonReset {
  border: 0px;
  background-color: transparent;
}

.buttonRepeat {
  border: 0px;
  background-color: transparent;
}

.MuiPaper-root {
  width: 750px !important;
  height: 400px !important;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-radius: 50px !important;
  opacity: 1 !important;
  max-width: 100% !important;
}

#endConversationTitle > .MuiTypography-h6 {
  text-align: center;
  font: normal normal bold 2.5vw/3vw Microsoft JhengHei UI;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  position: relative;
  top: 73px;
}

#endConversationContent {
  text-align: center;
  font: normal normal normal 0.75vw/1vw Microsoft JhengHei UI;
  letter-spacing: 0px;
  color: #ff0000;
  opacity: 1;
  position: relative;
  top: 59px;
}

#endConversationCancel {
  background-image: url("./Images/Button\ -\ Cancel.svg");
  background-size: 100% auto;
  width: 250px;
  height: 100px;
  margin: 30px 20px 50px 20px;
}

#endConversationConfirm {
  background-image: url("./Images/Button\ -\ Confirm.svg");
  background-size: 100% auto;
  width: 250px;
  height: 100px;
  margin: 30px 20px 50px 20px;
}

.HKUBackground {
  height: calc(100dvh - 160px);
  background-image: url("./Images/Background - Medical.svg");
  background-size: 100% auto;
}
.HKUWithDoctor {
  display: grid;
  grid-template-rows: calc(200px + 10vh) calc(100% - 200px - 10vh);
  height: 100%;
}
.homePageButton {
  position: fixed;
  top: 1vh;
  /* left: 0; change to right side */
  right: 0;
  background-image: url("./Images/Button\ -\ End\ Questionnaire.svg");
  transform: rotate(3.142rad);
  background-size: 100px 60px;
  background-repeat: no-repeat;
  width: 100px;
  height: 60px;
  z-index: 9999;
}

.voice-streaming-section {
  position: fixed;
  top: 50vh;
  right: 0;
  z-index: 9999;
}

.HKUButton {
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.AnsArrow {
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.HKUNextButton {
  width: 330px;
  height: 150px;
  background-image: url("./Images/Button\ -\ Start\ Questionnaire.svg");
  background-size: 100% 100%;
}

.QRDoctor {
  display: flex;
  justify-content: center;
}

.avatarArmsDoctor {
  bottom: 16%;
  position: fixed;
  left: calc(50% - 554px);
  height: 540px;
}

.avatarOrangeBagDoctor {
  bottom: 16%;
  position: fixed;
  left: calc(50% - 554px);
  height: 540px;
}

.QRNextButton {
  width: 330px;
  height: 150px;
  background-image: url("./Images/Icon\ -\ Scan\ QR.svg");
  background-size: 100% 100%;
}

.HKIDNextButton {
  width: 330px;
  height: 150px;
  background-image: url("./Images/Icon\ -\ HKIDform.svg");
  background-size: 100% 100%;
}

.EnterInfo {
  width: 330px;
  height: 150px;
  background-image: url("./Images/Icon\ -\ EnterInfo.svg");
  background-size: 100% 100%;
}

.FinishInfo {
  background-image: url("./Images/Button\ -\ FinishInfo.svg");
  background-size: 100% auto;
  width: 250px;
  height: 100px;
  margin: 30px 20px 50px 20px;
}

.StartNextButton {
  width: 330px;
  height: 150px;
  background-image: url("./Images/Button\ -\ Go\ to\ Questions.svg");
  background-size: 100% 100%;
}

.voice-recorder-section {
  position: fixed;
  top: 50vh;
  right: 2.5vw;
  display: flex;
  flex-direction: row-reverse;
}

.voiceReatButton {
  position: fixed;
  top: 32vh;
  left: 5vw;
  display: flex;
  flex-direction: row-reverse;
}

.voiceHint {
  text-align: right;
  font: normal normal bold 2vw/2.5vw Microsoft JhengHei UI;
  color: #ff0000;
  margin: 40px 20px;
}

.voiceHintMessage {
}

.voiceHintIcon {
  width: 71px !important;
  height: 71px !important;
  font-size: 2vw !important;
  position: relative;
  top: 18px;
}

.HKULogo {
  margin-bottom: 5%;
  width: 60%;
}

.entranceText {
  text-align: center !important;
  font: normal normal bold 2vw/2.5vw Microsoft JhengHei UI !important;
  letter-spacing: 0px !important;
  color: #0000ff !important;
  opacity: 1 !important;
}

.messageBar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topMessageBar {
  min-height: 180px;
}

.bottomMessageBar {
  position: fixed;
  left: 5vw;
  bottom: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #005f73;
  border-radius: 100px;
  width: 90vw;
  height: 150px;
  min-height: 150px;
}

.topMessageBar > .messageBox {
  width: 90%;
  margin-top: 10vh;
  height: 180px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #005f73;
  border-radius: 100px;
}

.bottomMessageBar > .messageBox {
  height: 150px;
  width: 100%;
}

.messageBoxMessage {
  text-align: center;
  width: 100%;

  font: normal normal normal 2vw/2.5vw Microsoft JhengHei UI;
  letter-spacing: 0px;
  color: #005f73;
  opacity: 1;
  display: inline-block;
  vertical-align: middle;
}

.topMessageBar > .messageBox > .oneLineMessage {
  white-space: nowrap;
  line-height: 180px;
}
.bottomMessageBar > .messageBox > .oneLineMessage {
  white-space: nowrap;
  line-height: 150px;
}

.topMessageBar > .messageBox > .twoLineMessage {
  line-height: 90px;
}

.bottomMessageBar > .messageBox > .twoLineMessage {
  line-height: 75px;
}

.redText {
  color: red;
  font-weight: bold;
}

.blueText {
  color: blue;
  font-weight: bold;
}
