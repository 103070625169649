.menu {
  left: -100px;
  transition: left 1s;
}

.menu:hover {
  left: 0px;
}

.menubtn:hover {
  color: black;
  background: white;
}

.menubtn.inPage {
  background: white;
}

.menubtn {
  color: rgba(242, 132, 62, 1);
}

.container {
  width: 1100px;
}
