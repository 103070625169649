.speech-container {
}

.speech-container.show {
  transform: scale(1);
  transition: transform 0.2s;
}

.speech-container.hide {
  transform: scale(0);
  transition: transform 0s;
}
.rev.speech-container {
}

.rev.speech-container.show {
  transform: scale(1);
  transition: transform 0.2s;
}

.rev.speech-container.hide {
  transform: scale(0);
  transition: transform 0s;
}

blockquote,
p {
  margin: 0;
  padding: 0;
}

.answer {
  position: relative;
  width: 300px;
  padding: 15px 25px 20px;
  margin: 20px auto;
  color: black;
  background: rgba(256, 256, 256, 80%);
  border: 1px solid #ca3422;

  &.quote {
    &:after {
      content: "";
      position: absolute;
      top: 100%;
      right: 25px;
      border-width: 30px 30px 0 0;
      border-style: solid;
      border-color: #ca3422 transparent;
    }

    blockquote {
      font: italic 26px / 1.3 "Lora", serif;
    }

    &.livechat {
      border: 1px solid #829f82;
      &:after {
        border-color: #829f82 transparent;
      }

      &.Men {
        border: 1px solid #843e83;
        &:after {
          border-color: #843e83 transparent;
        }
      }
    }
  }
}

.question {
  position: relative;
  width: 250px;
  padding: 5px;
  color: black;
  background: transparent;
}
