.zchat.sysmsg{
  margin: 1px;
  padding: 3px 15px;
  background-color: rgba(51, 51, 51, 0.1);
  text-align: center;
  border-radius: 15px;
  font-size: xx-small;
}

.zchat.bubble{
  margin: 5px 10px;
  padding: 5px 20px;
  max-width: 70%;
  box-shadow: 0 0 0.25cm rgba(163, 94, 40, 0.349);
  cursor: pointer;
}

.zchat.bubble.in{
  float: left;
  color: rgba(51, 51, 51, 1);
  border-radius: 10px;
}

.zchat.bubble.out{
  float: right;
  color: white;
  border-radius: 10px;
  background: linear-gradient(90deg, rgb(200, 123, 30) 0%, rgb(125, 60, 10) 75.75%, rgb(125, 60, 10) 100%);
}

.zchat.msg-text{
  margin: 2px 0;
  word-wrap: break-word;
}

.zchat.msg-text-readmore{
  margin: 5px 0;
  font-weight: bold;
  cursor: pointer;
  color: rgb(67, 169, 223);
}

.zchat.msg-image{
  width: 100%;
  margin: 2px 0;
}

.zchat.msg-image img{
  width: 100%;
  height: 100%;
}

.zchat.msg-video{
  width: 100%;
  margin: 2px 0;
}

.zchat.msg-btnlist{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.zchat.msg-btn{
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  background: linear-gradient(90deg, rgb(200, 123, 30) 0%, rgb(125, 60, 10) 75.75%, rgb(125, 60, 10) 100%);
  padding: 1px;
  margin: 2px 0;
}

.zchat.msg-btn.fitcontent{
  width: fit-content;
} 

.ie.zchat.msg-btn.fitcontent{
  display: table;
}

.zchat.msg-btn.disabled{
  opacity: 0.5;
}

.zchat.msg-btn-text,
.zchat.msg-btn-text.disabled:hover{
  padding: 5px 10px;
  text-align: center;
  color:rgb(200, 123, 30);
  font-weight: bold;
  background: white;
  border-radius: 5px;
}

.zchat.msg-btn-text:hover {
  color: white;
  background: linear-gradient(90deg, rgb(200, 123, 30) 0%, rgb(125, 60, 10) 75.75%, rgb(125, 60, 10) 100%);
}

.zchat.msg-imgbtnlist{
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.zchat.msg-imgbtn{
  width: 22%;
  margin: 5px 10px;
  cursor: pointer;
}

.zchat.msg-imgbtn.disabled{
  opacity: 0.5;
}

.zchat.msg-imgbtn-img img{
  width: 100%;
}

.zchat.msg-tcontainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.zchat.msg-tcontainer-arrows{
  width: fit-content;
  margin: 10px;
  color: rgb(200, 123, 30);
}

.zchat.msg-tlist{
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 212px;
}

.zchat.msg-tp{
  flex: 0 0 auto;
  width: calc(100% - 12px);
  padding: 5px 5px;
  scroll-padding: 1px;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px;
  border-color: rgba(31, 103, 176, 0.1);
}

.zchat.msg-tp-title-border{
  border-radius: 25px 25px 0 0 ;
  background: linear-gradient(90deg, rgb(200, 123, 30) 0%, rgb(125, 60, 10) 75.75%, rgb(125, 60, 10) 100%);
  padding: 1px 1px 0 1px;
}

.zchat.msg-tp-title{
  text-align: center;
  font-weight: bold;
  font-size: small;
  border-radius: 25px 25px 0 0 ;
  color: white;
}

.zchat.msg-tp-subtitle-border{
  background: linear-gradient(90deg, rgb(200, 123, 30) 0%, rgb(125, 60, 10) 75.75%, rgb(125, 60, 10) 100%);
  padding: 1px 1px 0 1px;
}

.zchat.msg-tp-subtitle{
  text-align: center;
  font-style: italic;
  font-size: x-small;
  color: white;
}

.zchat.msg-tp-img-border{
  background: linear-gradient(90deg, rgb(200, 123, 30) 0%, rgb(125, 60, 10) 75.75%, rgb(125, 60, 10) 100%);
  padding: 1px 1px 0 1px;
}

.zchat.msg-tp-img-border-end{
  background: linear-gradient(90deg, rgb(200, 123, 30) 0%, rgb(125, 60, 10) 75.75%, rgb(125, 60, 10) 100%);
  border-radius: 0 0 25px 25px;
  padding: 1px 1px 1px 1px;
}

.zchat.msg-tp-img{
  height: 200px;
  overflow: hidden;
  background: rgb(255, 246, 240);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
  margin: 2px 0;
}

.zchat.msg-tp-img img{
  width: 100%;
}

.zchat.msg-tp-video-border{
  background: linear-gradient(90deg, rgb(200, 123, 30) 0%, rgb(125, 60, 10) 75.75%, rgb(125, 60, 10) 100%);
  padding: 1px 1px 0 1px;
}

.zchat.msg-tp-video-border-end{
  background: linear-gradient(90deg, rgb(200, 123, 30) 0%, rgb(125, 60, 10) 75.75%, rgb(125, 60, 10) 100%);
  border-radius: 0 0 25px 25px;
  padding: 1px 1px 1px 1px;
}

.zchat.msg-tp-video{
  height: 200px;
  overflow: hidden;
  background: rgb(255, 246, 240);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
  margin: 2px 0;
}

.zchat.msg-tp-text-border{
  background: linear-gradient(90deg, rgb(200, 123, 30) 0%, rgb(125, 60, 10) 75.75%, rgb(125, 60, 10) 100%);
  padding: 1px 1px 0 1px;
}

.zchat.msg-tp-text-border-end{
  background: linear-gradient(90deg, rgb(200, 123, 30) 0%, rgb(125, 60, 10) 75.75%, rgb(125, 60, 10) 100%);
  border-radius: 0 0 25px 25px;
  padding: 1px 1px 1px 1px;
}


.zchat.msg-tp-text{
  font-size: xx-small;
  overflow-y: auto;
  height: 60px;
  padding: 0 10px;
  overflow-x: hidden;
  background: rgb(255, 246, 240);
}

.zchat.msg-tp-text-border-end .zchat-msg-tp-text{
  border-radius: 0 0 25px 25px;
}

.zchat.msg-tp-btn{
  text-align: center;
  cursor: pointer;
  background: linear-gradient(90deg, rgb(200, 123, 30) 0%, rgb(125, 60, 10) 75.75%, rgb(125, 60, 10) 100%);
  padding: 1px 1px 0 1px;
  margin: 2px 0;
}

.zchat.msg-tp-btn.disabled{
  opacity: 0.5;
}

.zchat.msg-tp-btn-text,
.zchat.msg-tp-btn-text.disabled:hover{
  padding: 5px 20px;
  color:rgb(200, 123, 30);
  font-weight: bold;
  font-size: xx-small;
  background: white;
  user-select: none;
}

.zchat.msg-tp-btn-text:hover{
  color: white;
  background: linear-gradient(90deg, rgb(200, 123, 30) 0%, rgb(125, 60, 10) 75.75%, rgb(125, 60, 10) 100%);
  
}

.zchat.msg-tp-btn.last{
  border-radius: 0 0 25px 25px;
  padding: 1px;
}

.zchat.msg-tp-btn-text.last{
  border-radius: 0 0 25px 25px;
}

.zchat.msg-header{
  cursor: pointer;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(200, 123, 30);
}

.zchat.msg-footer{
  user-select: none;
  font-size: xx-small;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px 0 0 0;
}

.zchat.msg-footer.in{
  color: rgb(179, 179, 179);
}

.zchat.msg-footer.out{
  color: rgb(231, 179, 116);
}

.zchat.msg-footer-lapse{
  text-align: left;
  flex-grow: 1;
  margin: 0 10px 0 0;
  font-style: italic;
}

.zchat.msg-footer-status{
  margin: 0 10px 0 0;
}

.zchat.msg-footer-status i{
  display: flex;
}

.zchat.msg-footer-datetime{
  flex-grow: 1;
  text-align: right;
}

@keyframes loading {
  from {background: rgb(0, 0, 0, 0.7)}
  to {background: rgb(0, 0, 0, 0.2)}
}

.zchat.zcmtyping{
  display: flex;
  flex-direction: row;
}

.zchat.zcmtyping-circles{
  width: 0.5em;
  height: 0.5em;
  margin: 2px;
  border-radius: 90px;
  background-color: rgb(0, 0, 0, 0.7);
  animation-name: loading;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.zchat.zcmtyping-circles:nth-child(0){
  animation-delay: 0s
}

.zchat.zcmtyping-circles:nth-child(1){
  animation-delay: 0.5s
}

.zchat.zcmtyping-circles:nth-child(2){
  animation-delay: 1s
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
  /* IE10+ specific styles go here */  
  
}