.left-menu-btn {
  width: 100%;
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;

  border-radius: 0px !important;
  border-bottom: 2px solid transparent !important;
  text-align: initial !important;
  &:hover {
    background: transparent !important;
    border-right: 2px solid #ca3422 !important;
  }
}
