.Alert2 .MuiDialog-paper {
  width: fit-content;
  height: auto;
  max-height: 250px;
  padding: 30px;
}

.Alert2 .MuiDialogContentText-root {
  font-size: 3em;
  color: #ff0000;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Alert2 .MuiDialog-root .MuiFade-child {
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
