.topBar {
  position: absolute;
  top: 1%;
  left: 150px;
  width: calc(100vw - 280px - 5vw);
  height: 60px;
  display: grid;
  grid-template-columns: 8vw calc(100% - 8vw);
  align-items: center;
}

#questionNumber {
  /* UI Properties */
  text-align: right;
  font: normal normal bold 1.5vw/2vw Microsoft JhengHei UI;
  letter-spacing: 0px;
  color: #005f73;
  opacity: 1;
  z-index: 9999;
}

#questionnaireStatusList {
  display: flex;
  align-items: center;
  justify-content: center;
}

#questionnaireStatus {
  /* UI Properties */
  text-align: left;
  font: normal normal bold 1vw/1.25vw Microsoft JhengHei UI;
  letter-spacing: 0px;
  color: #005f73;
  opacity: 1;
  z-index: 9999;
}

#questionNumbersList {
  display: flex;
}

.questionNumberIcon {
  margin: 0 10px;
  width: 3vw !important;
  height: 3vw !important;
}

.questionDone {
  background-color: #0000ff !important;
}

.questionCurrent {
  background-color: #005f73 !important;
}

.questionNew {
  background-color: #a2c5cd !important;
}

#goBackToPreviousQuestion {
  /* right: 0; change to left side */
  left: 0;
  z-index: 9999;
  position: fixed;
  top: 1%;
  width: 180px;
  height: 60px;
  background-image: url("../Images/Button\ -\ Previous\ Question.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
}

#repeatVoice {
  width: 15vw;
  height: 7vw;
  background-image: url("../Images/Button\ -\ repeatVoice.svg");
  background-size: 100% 100%;
}

.questionValue {
  width: 100%;
  height: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.buttonReset.questionValue:disabled {
  border: none;
  transform: none;
  cursor: default;
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}

.buttonReset.questionValue:disabled:active {
  border: none;
  transform: none;
  cursor: default;
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}

.buttonReset.questionValue.buttonPress {
  transform: scale(1.3);
  border: 10px solid gold;
  border-radius: 120px;
  z-index: 1;
  filter: none;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.BTN_Arrow {
  position: relative;
  bottom: 15vh !important;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  animation: blink 1s infinite;
  z-index: 5;
}

#voiceRecorderButton {
  width: 10vw;
  height: 10vw;
  border-radius: 50px;
  background-image: url("../Images/Button\ -\ Microphone.svg");
  background-size: 100% 100%;
}

#voiceRecorderButton:active:hover {
  border: 2px solid #005f73;
}

#confirmVoiceMessage {
  position: fixed;
  bottom: 30px;
  right: calc(5vw + 30px);
  width: 250px;
  height: 100px;
  background-image: url("../Images/Button\ -\ Confirm.svg");
  background-size: 100% 100%;
}

#backToQuestionTop {
  right: 0;
  z-index: 9999;
  position: fixed;
  top: 1vh;
  width: 180px;
  height: 60px;
  background-image: url("../Images/Button\ -\ Back\ to\ Question.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
}

#backToQuestionBottom {
  position: absolute;
  bottom: 25px;
  left: calc(90% - 250px);
  width: 250px;
  height: 100px;
  background-image: url("../Images/Button\ -\ Back\ to\ Question\ from\ Speech.svg");
  background-size: 100% 100%;
}

.feedbackBox {
  width: 550px;
  height: 50px;
  line-height: 50px;

  position: absolute;
  bottom: 170px;
  left: 50px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #005f73;
  border-radius: 25px;

  text-align: center;
}

.feedbackBox > span {
  font: normal normal normal 0.75/1vw Microsoft JhengHei UI;
  letter-spacing: 0px;
  color: blue;
}

#questionValue1 {
  background-image: url("../Images/Button\ -\ 1\ No\ Difficulty.svg");
}

#questionValue2 {
  background-image: url("../Images/Button\ -\ 2\ Little\ Difficulty.svg");
}

#questionValue3 {
  background-image: url("../Images/Button\ -\ 3\ Some\ Difficulty.svg");
}

#questionValue4 {
  background-image: url("../Images/Button\ -\ 4\ Very\ Difficult.svg");
}

#questionValue5 {
  background-image: url("../Images/Button\ -\ 5\ Unable\ to\ Do.svg");
}

#questionValue6 {
  background-image: url("../Images/Button\ -\ 6\ Never.svg");
}

#questionValue7 {
  background-image: url("../Images/Button\ -\ 7\ Little\ bit.svg");
}

#questionValue8 {
  background-image: url("../Images/Button\ -\ 8\ Mid.svg");
}

#questionValue9 {
  background-image: url("../Images/Button\ -\ 9\ Very.svg");
}

#questionValue10 {
  background-image: url("../Images/Button\ -\ 10\ Extreme.svg");
}

#questionValue11 {
  background-image: url("../Images/Button\ -\ 11\ No.svg");
}

#questionValue12 {
  background-image: url("../Images/Button\ -\ 12\ Little.svg");
}

#questionValue13 {
  background-image: url("../Images/Button\ -\ 13\ Mid.svg");
}

#questionValue14 {
  background-image: url("../Images/Button\ -\ 14\ Serious.svg");
}

#questionValue15 {
  background-image: url("../Images/Button\ -\ 15\ Extreme.svg");
}

#questionValue16 {
  background-image: url("../Images/Button\ -\ 16\ No\ Difficulty.svg");
}

#questionValue17 {
  background-image: url("../Images/Button\ -\ 17\ Little\ Difficulty.svg");
}

#questionValue18 {
  background-image: url("../Images/Button\ -\ 18\ Some\ Difficulty.svg");
}

#questionValue19 {
  background-image: url("../Images/Button\ -\ 19\ Very\ Difficult.svg");
}

#questionValue20 {
  background-image: url("../Images/Button\ -\ 20\ Unable\ to\ sleep.svg");
}
