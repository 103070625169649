.resultTopBar {
  /* Layout Properties */
  top: 2%;
  left: 684px;
  width: 600px;
  height: 27px;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 20px/27px Segoe UI;
  letter-spacing: 0px;
  color: #ff0000;
  opacity: 1;
  position: absolute;
}

.resultBottomBar {
  /* Set the container to be a flex container */
  display: flex;
  flex-direction: column; /*add*/
  /* Center items both vertically and horizontally */
  justify-content: center;
  align-items: center;
  /* Adjust the size of the bottom bar as needed */
  height: 300px; /* change from 200px to 250px */
}

#endResultScore {
  position: absolute;
  /* 800px to 700px */
  top: -150%;
  /* left: 1021px; */
  width: 69px;
  height: 80px;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 60px/80px Segoe UI;
  letter-spacing: 0px;
  color: #27ff32;
  opacity: 1;
  z-index: 3;
}

#endResultArrow {
  position: absolute;
  top: -30%;
  transform: translate(-50%, -50%);
  color: red;
  z-index: 1;
  /* width: 50px;
  height: 50px; */
}

#endResultBar {
  /* position: absolute;
  bottom: 20px;
  left: 75px; */
  position: relative;
  width: 130%;
  height: 40px; /*change from 50px to 40px*/
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

.endResultButton {
  /* bottom: 5px;
  right: 45px; */
  width: 330px;
  height: 150px;
  background-image: url("../Images/Button\ -\ Complete\ Questionnaire.svg");
  background-size: 100% 100%;
  /* position: absolute; */
  z-index: 2;
}

.endResultButton2 {
  /* bottom: 5px;
  right: 45px; */
  width: 330px;
  height: 150px;
  background-image: url("../Images/Button\ -\ Print\ Questionnaire.svg");
  background-size: 100% 100%;
  /* position: absolute; */
  z-index: 2;
}

.Alert .MuiDialog-paper {
  width: fit-content;
  max-width: 650px;
  height: auto;
  max-height: 200px;
  padding: 40px;
}

.Alert .MuiDialogContentText-root {
  font-size: 3em;
  color: #ff0000;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Alert .MuiDialog-root .MuiFade-child {
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.AlertQR .MuiDialog-paper {
  width: fit-content;
  max-width: 650px;
  height: 800px !important;
  padding: 40px;
}

.AlertQR .MuiDialogContentText-root {
  font-size: 3em;
  color: #ff0000;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.AlertQR .MuiDialog-root .MuiFade-child {
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.reprint .MuiDialog-paper {
  width: fit-content;
  max-width: 650px;
  height: 400px !important;
  padding: 40px;
}

.reprint .MuiDialogContentText-root {
  font-size: 3em;
  color: #ff0000;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.reprint .MuiDialog-root .MuiFade-child {
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
