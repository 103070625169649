.zchat.outermost{
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  font-family: 'Microsoft JhengHei', Calibri, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue";
}

.zchat.msglist{
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.zchat.msgrow{
  width: 100%;
  display: flex;
  margin: 10px 0;
  justify-content: center;
  align-items: flex-end;
}

.zchat.msgrow.attop{
  align-items: flex-start;
}

.zchat.touch{
  width: 100%;
  opacity: 1;
}

.zchat.touch.pressed{
  opacity: 0.5;
}

.zchat.touch.disabled{
  opacity: 1;
}

.zchat.scroll{
  width: 100%;
  opacity: 1;
}

.zchat.scroll.pressed{
  opacity: 0.5;
}

.zchat.scroll.disabled{
  opacity: 1;
}

.zchat.avatarbox{
  min-width: 30px;
  max-width: 30px;
}

.zchat.avatar{
  margin: 0;
  padding: 0;
  align-items: baseline;
  border-radius: 500px;
  overflow: hidden;
  display: flex;
}

.zchat.avatar img{
  width: 100%;
}

.zchat.inputfield textarea::-webkit-scrollbar,
.zchat.msg-qrlist::-webkit-scrollbar,
.zchat.msg-tp-text::-webkit-scrollbar,
.zchat.msglist::-webkit-scrollbar,
.zchat.msg-tlist::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
.zchat.inputfield textarea::-webkit-scrollbar-track,
.zchat.msg-qrlist::-webkit-scrollbar-track,
.zchat.msg-tp-text::-webkit-scrollbar-track,
.zchat.msglist::-webkit-scrollbar-track,
.zchat.msg-tlist::-webkit-scrollbar-track {
  background: rgb(233, 242, 247);
}
 
/* Handle */
.zchat.inputfield textarea::-webkit-scrollbar-thumb,
.zchat.msg-qrlist::-webkit-scrollbar-thumb,
.zchat.msg-tp-text::-webkit-scrollbar-thumb,
.zchat.msglist::-webkit-scrollbar-thumb,
.zchat.msg-tlist::-webkit-scrollbar-thumb {
  background: rgb(200, 123, 30);
  background: linear-gradient(180deg, rgb(200, 123, 30) 0%, rgb(125, 60, 10) 75.75%, rgb(125, 60, 10) 100%);
}

/* Handle on hover */
.zchat.inputfield textarea::-webkit-scrollbar-thumb:hover,
.zchat.msg-qrlist::-webkit-scrollbar-thumb:hover,
.zchat.msg-tp-text::-webkit-scrollbar-thumb:hover,
.zchat.msglist::-webkit-scrollbar-thumb:hover,
.zchat.msg-tlist::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 123, 30);
}

.zchat.msg-qrlist::-webkit-scrollbar{
  display: none;
}

@media only screen and (max-width: 768px){
  .zchat.avatarbox{
    min-width: 35px;
    max-width: 35px;
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
  /* IE10+ specific styles go here */  
  
}
